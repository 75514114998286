import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from "prop-types";
import React from "react";
import {withStyles} from "@material-ui/core";
import TableSortLabel from '@material-ui/core/TableSortLabel';

const headCells = [
    // { id: 'Action', numeric: false, disablePadding: true, label: 'Test', },
    { id: 'name', numeric: false, disablePadding: true, label: 'Name'},
    { id: 'missingDataCheck', numeric: false, disablePadding: false, label: 'Missing Data Check' },
    { id: 'maintenanceModeCheck', numeric: false, disablePadding: false, label: 'Maintenance Mode' },
];

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontWeight: "bold"
    }
}))(TableCell);

export function EnhancedTableHeader(props) {

    const { classes, order, orderBy, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell />
                {headCells.map((headCell) => {
                    return (
                        <StyledTableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >

                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >


                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden} />
                                ) : null}


                                {headCell.label}


                            </TableSortLabel>


                        </StyledTableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};