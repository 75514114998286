import React from "react";
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import moment from 'moment';
import ReactHighcharts from "react-highcharts";
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import {changeSelectedDataStream, selectDataStream,} from '../../store/appSlice';
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 100,
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 1,
        // color: '#fff',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexWrap: 'wrap',
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    dsList: {
        width: '100%',
        // maxWidth: '36ch',
        overflow: 'auto',
        height: '480px',
        direction: 'rtl'
    },
    paper: {
        // padding: theme.spacing(2),
        margin: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        height: '480px',
    },
    inline: {
        display: 'inline',
    },
}));

function _getMinsSinceLastSampleLabel(minsSinceLastSample) {

    if(isNaN(minsSinceLastSample)) {
        return "--";
    }

    let l = "Last sample ";

    switch (true) {
        case (minsSinceLastSample > 1440):
            l += Math.trunc(minsSinceLastSample / 1440) + " day(s) ago";
            break;
        case (minsSinceLastSample > 60):
            l += Math.trunc(minsSinceLastSample / 60) + " hour(s) ago";
            break;
        default:
            l += minsSinceLastSample + " minutes ago";
    }

    return l;

}

function DataStreamStats(props) {

    const dispatch = useDispatch();

    const {dsStats, isActive, dateRange} = props;

    const classes = useStyles();

    const handleListItemClick = (event, dataStream) => {

        dispatch(changeSelectedDataStream({
            dataStream: dataStream,
            startDtMillis: moment().subtract(
                dateRange.split("_")[0],
                dateRange.split("_")[1]).valueOf(),
            endDtMillis: moment.now().valueOf()
        }));

    };

    let tooltipLabel = (dsStats.isLate) ? "[LATE] " : "[ON TIME] ";

    tooltipLabel +=  _getMinsSinceLastSampleLabel(dsStats.minutesSinceLastDataPoint);

    return (

        <ListItem
                    alignItems="flex-start"
                    key={dsStats.dataStreamId}
                    button
                    selected={isActive}
                    onClick={(event) => handleListItemClick(event, dsStats)}

        >
            <Tooltip arrow placement="top-start" title={tooltipLabel}>
                <ListItemIcon>

                    { (dsStats.isLate)
                        ? <ErrorOutlineIcon  style={{color: red[500]}} />
                        : <CheckCircleOutlineIcon  style={{color: green[500]}} />
                    }
                </ListItemIcon>

            </Tooltip>

                <ListItemText
                    primary={dsStats.parameterName}
                    secondary={
                        <React.Fragment>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                // color="textPrimary"
                            >

                                Datastream:  {dsStats.dataStreamId}

                            </Typography>
                        </React.Fragment>
                    }
                />

        </ListItem>

    );



}

export function DslDetails(props) {

    const {stats} = props;

    const classes = useStyles();

    const selectedDs = useSelector(selectDataStream);

    const dispatch = useDispatch();

    const [selectedDateRange, setSelectedDateRange] = React.useState("1_days");

    const handleDateRangeChange = (e, payload) => {

        setSelectedDateRange(payload.dateRange);

        dispatch(changeSelectedDataStream({
            dataStream: payload.dataStream,
            startDtMillis: moment().subtract(
                    payload.dateRange.split("_")[0],
                    payload.dateRange.split("_")[1]).valueOf(),
            endDtMillis: moment.now().valueOf()
        }))

    };

    if (stats) {

        return (

            <React.Fragment>

                <Grid container style={{backgroundColor: "#f8f8f8"}} >

                        <Grid item xs={12} sm={4} >

                            <Card className={classes.paper}>
                                <List className={classes.dsList} >
                                    {

                                        stats.statsByDataStream.map((dsStats, i) => {

                                            return (

                                                <DataStreamStats
                                                    isActive={(selectedDs.dataStream && selectedDs.dataStream.dataStreamId === dsStats.dataStreamId)}
                                                    key={dsStats.dataStreamId}
                                                    dsStats={dsStats}
                                                    dateRange={selectedDateRange}
                                                />
                                            )

                                        })

                                    }

                                </List>

                            </Card>

                        </Grid>

                        <Grid item xs={12} sm={8} >

                            {/*TODO: Render time series graph here*/}

                            <Card className={classes.paper}>

                                <CardActions>

                                    <RadioGroup row aria-label="position" name="position">
                                        <FormControlLabel
                                            value="1_hours"
                                            label="1h"
                                            checked={selectedDateRange === "1_hours"}
                                            onChange={(e) => handleDateRangeChange(e, {dataStream: selectedDs.dataStream, dateRange: e.target.value})}
                                            control={<Radio color="primary" />}
                                        />
                                        <FormControlLabel
                                            value="1_days"
                                            label="1d"
                                            checked={selectedDateRange === "1_days"}
                                            onChange={(e) => handleDateRangeChange(e, {dataStream: selectedDs.dataStream, dateRange: e.target.value})}
                                            control={<Radio color="primary" />}
                                        />
                                        <FormControlLabel
                                            value="1_weeks"
                                            label="1w"
                                            checked={selectedDateRange === "1_weeks"}
                                            onChange={(e) => handleDateRangeChange(e, {dataStream: selectedDs.dataStream, dateRange: e.target.value})}
                                            control={<Radio color="primary" />}
                                        />
                                        <FormControlLabel
                                            value="1_months"
                                            label="1m"
                                            checked={selectedDateRange === "1_months"}
                                            onChange={(e) => handleDateRangeChange(e, {dataStream: selectedDs.dataStream, dateRange: e.target.value})}
                                            control={<Radio color="primary" />}
                                        />
                                        <FormControlLabel
                                            value="3_months"
                                            label="3m"
                                            checked={selectedDateRange === "3_months"}
                                            onChange={(e) => handleDateRangeChange(e, {dataStream: selectedDs.dataStream, dateRange: e.target.value})}
                                            control={<Radio color="primary" />}
                                        />


                                    </RadioGroup>


                                    {
                                        (selectedDs.dataStream)
                                            ?
                                            <div>
                                                <small>{_getMinsSinceLastSampleLabel(selectedDs.dataStream.minutesSinceLastDataPoint)}</small>
                                            </div>

                                            : null

                                    }


                                </CardActions>


                                <CardContent>

                                    {/*// TODO: Move to a separate component*/}
                                    {
                                        (selectedDs.isLoading || !selectedDs.dataStream)
                                        ?
                                            <CircularProgress/>

                                        :
                                            <ReactHighcharts
                                                config={{
                                                    title: {
                                                        style: {
                                                            display: 'none'
                                                        }
                                                    },
                                                    chart: {
                                                        zoomType: 'xy'
                                                    },
                                                    xAxis: {
                                                        type: 'datetime',
                                                    },
                                                    time : {
                                                        timezoneOffset: -moment().utcOffset() // Display in local time
                                                    },
                                                    credits: {
                                                        enabled: false
                                                    },
                                                    series: [
                                                        {
                                                            data: selectedDs.timeSeriesData,
                                                            name: selectedDs.dataStream.parameterName + " POC " + selectedDs.dataStream.poc
                                                        }
                                                    ]
                                                }}
                                            />


                                    }



                                </CardContent>

                            </Card>


                        </Grid>

                </Grid>

            </React.Fragment>

        );

    }

    return (
        <React.Fragment>

            <Typography variant="h6" className={classes.title} style={{textAlign: 'center'}}>
                No Reporting Status Available
            </Typography>

        </React.Fragment>

    );

}