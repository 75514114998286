import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useDispatch, useSelector} from "react-redux";
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { loadFromLocalStorage } from '../../store/localStorage';
import {getDslListByAgency, setPendingAgencyTransition, selectAppUi, selectAppData} from "../../store/appSlice";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, selectedTab, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {selectedTab === index &&
            <Box p={3}>
                {children}
            </Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    selectedTab: PropTypes.any.isRequired
};

function DslEventHeatmap() {

    const uiState = useSelector(selectAppUi);

    const tabElements = [];

    const tabPanelElements = [];

    const dispatch = useDispatch();

    const appData = useSelector(selectAppData);

    const agencies = loadFromLocalStorage("foundAgencies"); //INS 1229

    Object.entries(agencies).forEach( ([i, agencyConfig]) => {

        // TODO: more modular
        // Fixes FNC-976
        if(typeof appData[agencyConfig.code] === 'undefined') {
            console.log("Agency", agencyConfig.code, "not found in appData");
            return;
        }
        const agencyDslList = appData[agencyConfig.code];

        //.debug(agencyDslList);
        const activeEvents = {
            numLate: 0,
            numInMaintenance: 0
        }


        agencyDslList.forEach((dsl) => {

            if (dsl.json.missingDataCheck.isEnabled && dsl.stats.isLate) {
                activeEvents.numLate++;
            }
            if (dsl.json.maintenanceModeCheck.isEnabled) {
                activeEvents.numInMaintenance++;
            }
        });

        const numActiveEvents = (activeEvents.numLate + activeEvents.numInMaintenance);

        const tooltipText = (numActiveEvents > 0) ? agencyConfig.code + " has " + numActiveEvents + " active 'Missing Data' or 'Maintenance' events." :  "";

        tabElements.push(
            <Tab
                label={
                    <Tooltip title={tooltipText} arrow >
                        <Badge
                            badgeContent={numActiveEvents}
                            color="secondary" >
                            {agencyConfig.code}
                        </Badge>
                    </Tooltip>

                }
                key={agencyConfig.guid} {...a11yProps(parseInt(i))}
            />
        );

        tabPanelElements.push(
            <TabPanel
                selectedTab={uiState.selectedAgency.index}
                key={agencyConfig.guid}
                index={parseInt(i)}>

                <h1>Not implemented yet</h1>

            </TabPanel>

        );

    });

    const handleChangeSelectedTab = (tabIdx) => {

        if (uiState.isDirty) {
            dispatch(setPendingAgencyTransition({isPendingTransition: true, isPendingTargetIndex: tabIdx}));
        } else {
            dispatch(getDslListByAgency(tabIdx));
        }

    }

    return (

        <div style={{height: "100vh"}} >

            <AppBar position="static" color="primary">

                <Tabs centered value={uiState.selectedAgency.index} onChange={(e, v) => handleChangeSelectedTab(v)} aria-label="simple tabs example">

                    {tabElements}

                </Tabs>

            </AppBar>

            {tabPanelElements}

        </div>

    );


};

export default DslEventHeatmap;