import React, {useEffect, useRef} from 'react';
import LoginForm from "./components/login/LoginForm";
import {useDispatch, useSelector} from "react-redux";
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import {closeMessage, keepAlive, login, logout, selectAppUi, selectAuth} from "./store/appSlice";
import AppHeader from './components/navigation/Header';
import DslInventoryManagement from './components/inventory/DslInventoryManagement';
import {ContentViewEnum} from './config/APP_CONSTANTS';

import {  makeStyles} from '@material-ui/core/styles';
import DslEventHeatmap from "./components/heatmap/EventHeatmap";
import {loadFromLocalStorage} from "./store/localStorage";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "white",

    },
    login: { // TODO: Move to login component
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        backgroundImage: `url(${require("./ftt-background.jpg")})`,
        backgroundPosition: "center",
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",

    }
}));





function App() {

    /****** HOOKS *******/

    const dispatch = useDispatch();

    const auth =  useSelector(selectAuth);

    const uiState = useSelector(selectAppUi);

    const classes = useStyles();

    const wrapperRef = useRef(null);

    useEffect( () => { // Analogous to componentDidMount

        const authRefreshIntervalMins = 15;

        // On initial mount, we may already have an auth token stored in LocalStorage.
        // However, this token can go stale if the user closes the page
        // TODO: Update auth.dt on any API call, as these also have the side-effect of resetting
        // the session timeout clock as well
        if (auth && auth._dt) {
            const tokenAgeMins = moment().diff(moment(auth._dt), 'minutes');
            console.info("Auth token " +  auth.token + " is " + tokenAgeMins + " minutes old.");
            if (tokenAgeMins > authRefreshIntervalMins) {
                console.info("Token revoked");
                dispatch(logout());
            }

        }

        // Refresh Auth token to keep the session alive
        setInterval(() => {
            dispatch(keepAlive());
        },1000 * 60 * authRefreshIntervalMins);

    }, [dispatch]);

    /****** END HOOKS *******/


    /**** ACTION HANDLERS *****/

    // TODO: Move out of here
    const handleCloseMessageBar = (event, reason) => {
        dispatch(closeMessage());
    };


    // TODO: Move out of here
    const handleSubmitLogin = (username, password) => {

        dispatch(
            login({
                username: username,
                password: password
        }));


    };


    /********* MAIN RENDER **********/

    if (!auth || !auth.token || !loadFromLocalStorage("foundAgencies")/*TBD - introduce state/action/reducer for agencies*/ ) {
        if(auth && auth.token && !loadFromLocalStorage("foundAgencies")){
            console.debug("Found auth but did not find agencies in localStorage") ;// TBD reproduce and handle this case better
        }
        return (
            <div className={classes.login}>

                <Snackbar
                    ref={wrapperRef}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'middle',
                    }}
                    open={uiState.message.isActive}
                    onClose={()=> handleCloseMessageBar()}
                    message={uiState.message.text}
                    action={
                        <React.Fragment >
                            <Button color="secondary" size="small" onClick={()=> handleCloseMessageBar()}>
                                CLOSE
                            </Button>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={()=> handleCloseMessageBar()}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />

                <LoginForm onSubmit={handleSubmitLogin} isLoading={uiState.isLoading} isLoginError={uiState.isLoginError} />

            </div>
        );
    }

    const renderConventView = () =>{

        if (uiState.selectedView === ContentViewEnum.DSL_EVENT_HEATMAP) {
            return <DslEventHeatmap />
        } else {
            return <DslInventoryManagement  />
        }
    };


    return ( // Main render

        <React.Fragment key={'appMain'}>

                <AppHeader />

                {renderConventView()}

                <div ref={wrapperRef}> {/* Known issue with this dependency: (OPEN) WARNING for elements that rely on findDOMNode https://github.com/mui-org/material-ui/issues/13394*/}

                    {/* TODO: Move Snackbar to a separate component*/}
                    <Snackbar
                        ref={wrapperRef}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        open={uiState.message.isActive}
                        autoHideDuration={6000}
                        onClose={()=> handleCloseMessageBar()}
                        message={uiState.message.text}
                        action={
                            <React.Fragment >
                                <Button color="secondary" size="small" onClick={()=> handleCloseMessageBar()}>
                                    CLOSE
                                </Button>
                                <IconButton size="small" aria-label="close" color="inherit" onClick={()=> handleCloseMessageBar()}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    />

                </div>

        </React.Fragment>

    );
}

export default App;
